<template>
  <div>
    <iframe class="iframe" :src="pdfUrl" style="height: 100%" width="100%"></iframe>
  </div>
</template>

<script>
export default {
  name: "Index",
  components: {},
  data() {
    return {
      pdfUrl: 'https://zhoululu.oss-cn-shenzhen.aliyuncs.com/permanent/%E5%91%A8%E9%9C%B2%E6%B1%9F-Java%20.pdf',
    };
  },
  mounted() {
  },
  methods: {},
}
</script>

<style scoped>
.iframe {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>
