<template>
    <div :class="[uploadFileStatus?'uploading':'']" class="box">
        <div style="height: 105px">
            <el-upload
                :auto-upload='false'
                :before-remove="beforeRemove"
                :file-list="fileList"
                :on-change="handleChange"
                action=""
                style="width: 300px"
            >
                <el-button slot="trigger" size="small" type="primary" @click="delFile" :disabled="uploadFileStatus">{{ delFileTitle }}</el-button>
                <el-button style="margin-left: 10px;" size="small" type="success" @click="uploadFile" :disabled="uploadFileStatus|| fileAddress!=null">上传到服务器</el-button>
                <el-progress class="el-upload__tip" slot="tip" :text-inside="true" :stroke-width="20" :percentage="uploadProgress" status="success"></el-progress>
            </el-upload>
        </div>
    </div>
</template>

<script>
import OSS from 'ali-oss'
import FileApi from "@/api/file";

export default {
    props: {
        fileType: {
            type: String,
            required: false,
        },
    },
    name: "OSSUploadFile",
    data() {
        return {
            uploadProgress: 0,
            fileList: [],
            uploadFileStatus: false,
            delFileTitle: '选取文件',
            ossClient: null,
            region: '',
            accessKeyId: '',
            accessKeySecret: '',
            stsToken: '',
            bucket: '',
            cancel: false,
            fileAddress: null,
            endpoint: '',
        }
    },
    created() {
        this.obtainAuthority();
    },
    methods: {
        obtainAuthority() {
            FileApi.authority().then(res => {
                const {endpoint,accessKeyId, accessKeySecret, bucket, region, stsToken} = res.data
                this.accessKeyId = accessKeyId;
                this.accessKeySecret = accessKeySecret;
                this.bucket = bucket;
                this.region = region;
                this.stsToken = stsToken;
                this.endpoint = endpoint;
            })
        },
        //重新选择文件
        delFile() {
            this.fileList = [];
            this.uploadFileStatus = false
            this.fileAddress = null
            this.$emit('delFile');
        },
        //移除文件
        beforeRemove(file, fileList) {
            this.fileList = fileList;
            this.uploadProgress = 0
            this.delFileTitle = '选取文件'
            this.uploadFileStatus = false
            this.fileAddress = null
        },
        //文件改变时
        handleChange(file, fileList) {
            this.fileList = []
            this.fileList.push(file);
            this.delFileTitle = '重新选取'
            this.uploadFileStatus = false
        },
        uuid() {
            let d = new Date().getTime();
            if (window.performance && typeof window.performance.now === "function") {
                d += performance.now(); // use high-precision timer if available
            }
            const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                const r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        //识别文件格式
        discriminateType(file) {
            if (!(this.fileType === file.type)) {
                if ('application/vnd.openxmlformats-officedocument.presentationml.presentation' === file.type) {
                    this.$message.error('只支持' + 'PPTX' + '格式');
                } else if ('video/mp4') {
                    this.$message.error('只支持' + 'MP4' + '格式');
                } else if ('application/x-zip-compressed') {
                    this.$message.error('只支持' + 'zip' + '格式');
                } else {
                    this.$message.error('格式不对');
                }
                return false;
            }
            return true
        },
        // 上传文件
        uploadFile() {
            if (!this.fileList.length > 0) {
                return
            }
            let file = this.fileList[0].raw
            //防止重复提交
            this.uploadFileStatus = true;
            this.ossClient = new OSS({
                region: this.region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: this.accessKeyId,
                accessKeySecret: this.accessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: this.stsToken,
                // 填写Bucket名称。
                bucket: this.bucket,
                secure: true,
                endpoint: this.endpoint
            });
            const fileName = this.uuid() + '.' + file.name.split('.').pop();
            this.ossClient.multipartUpload("temp/public/" + fileName, file, {
                progress: (p) => {
                    if (this.cancel) {
                        this.ossClient.cancel();
                        this.cancel = false
                    }
                    this.uploadProgress = Math.round(p * 100);
                }
            }).then((result) => {
                const {status, requestUrls} = result.res
                if (status === 200) {
                    this.$message.success("上传成功");
                    const url = requestUrls[0];
                    const questionMarkIndex = url.indexOf('?');
                    let resultUrl = '';
                    if (questionMarkIndex > -1) {
                        resultUrl = url.substring(0, questionMarkIndex);
                    } else {
                        resultUrl = url
                    }
                    this.fileAddress = resultUrl
                    this.successRespondedMethods();
                }
            }).catch((err) => {
                //console.log(err);
            }).finally(() => {
                this.uploadFileStatus = false
            })
        },
        //成功响应
        successRespondedMethods() {
            this.$emit('successResponded', this.fileAddress);
        },
        //取消上传
        cancelUpload() {
            if (this.uploadFileStatus) {
                this.ossClient.cancel();
            }
        }
    }
}
</script>

<style lang="less" scoped>
.box {
    height: 105px;
    width: 200px;
    box-sizing: border-box;
    border: 1px solid red;

}
.bt-operate{
    display: flex;
    justify-content: space-between;
    .bt-operate-item{
        width: 100px;
    }
}
</style>
