<template>
    <div class="box">
        <div class="tool-content">
            <div>
                颜色在线转换
            </div>
            <div class="count-input">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-input placeholder="请输入HEX值 如:#FFFFFF 或 #FFF" v-model="HEXInput">
                            <template slot="prepend">HEX</template>
                        </el-input>
                    </el-col>
                    <el-col :span="12">
                        <el-input v-model="RGBOut">
                            <template slot="prepend">RGB</template>
                        </el-input>
                    </el-col>
                </el-row>
                <br>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-input placeholder="请输入RGB值 如:255,255,255" v-model="RGBInput">
                            <template slot="prepend">RGB</template>
                        </el-input>
                    </el-col>
                    <el-col :span="12">
                        <el-input v-model="HEXOut">
                            <template slot="prepend">HEX</template>
                        </el-input>
                    </el-col>
                </el-row>
            </div>
            <br>
            <div>
                日常颜色大全
            </div>
            <div class="table-box">
                <table border="0">
                    <tbody>
                    <tr>
                        <td height="30" width="95" bgcolor="#ffffff"> #FFFFFF</td>
                        <td height="30" width="95" bgcolor="#dddddd"> #DDDDDD</td>
                        <td height="30" width="95" bgcolor="#aaaaaa"> #AAAAAA</td>
                        <td height="30" width="95" bgcolor="#888888"> #888888</td>
                        <td height="30" width="95" bgcolor="#666666"> #666666</td>
                        <td height="30" width="95" bgcolor="#444444"> #444444</td>
                        <td height="30" width="95" bgcolor="#000000"> #000000</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#ffb7dd"> #FFB7DD</td>
                        <td height="30" width="95" bgcolor="#ff88c2"> #FF88C2</td>
                        <td height="30" width="95" bgcolor="#ff44aa"> #FF44AA&nbsp;</td>
                        <td height="30" width="95" bgcolor="#ff0088"> #FF0088&nbsp;</td>
                        <td height="30" width="95" bgcolor="#c10066"> #C10066&nbsp;</td>
                        <td height="30" width="95" bgcolor="#a20055"> #A20055&nbsp;</td>
                        <td height="30" width="95" bgcolor="#8c0044"> #8C0044&nbsp;</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#ffcccc"> #FFCCCC</td>
                        <td height="30" width="95" bgcolor="#ff8888"> #FF8888</td>
                        <td height="30" width="95" bgcolor="#ff3333"> #FF3333&nbsp;</td>
                        <td height="30" width="95" bgcolor="#ff0000"> #FF0000&nbsp;</td>
                        <td height="30" width="95" bgcolor="#cc0000"> #CC0000&nbsp;</td>
                        <td height="30" width="95" bgcolor="#aa0000"> #AA0000&nbsp;</td>
                        <td height="30" width="95" bgcolor="#880000"> #880000&nbsp;</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#ffc8b4"> #FFC8B4</td>
                        <td height="30" width="95" bgcolor="#ffa488"> #FFA488</td>
                        <td height="30" width="95" bgcolor="#ff7744"> #FF7744&nbsp;</td>
                        <td height="30" width="95" bgcolor="#ff5511"> #FF5511&nbsp;</td>
                        <td height="30" width="95" bgcolor="#e63f00"> #E63F00&nbsp;</td>
                        <td height="30" width="95" bgcolor="#c63300"> #C63300&nbsp;</td>
                        <td height="30" width="95" bgcolor="#a42d00"> #A42D00&nbsp;</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#ffddaa"> #FFDDAA</td>
                        <td height="30" width="95" bgcolor="#ffbb66"> #FFBB66</td>
                        <td height="30" width="95" bgcolor="#ffaa33"> #FFAA33</td>
                        <td height="30" width="95" bgcolor="#ff8800"> #FF8800&nbsp;</td>
                        <td height="30" width="95" bgcolor="#ee7700"> #EE7700&nbsp;</td>
                        <td height="30" width="95" bgcolor="#cc6600"> #CC6600&nbsp;</td>
                        <td height="30" width="95" bgcolor="#bb5500"> #BB5500&nbsp;</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#ffee99"> #FFEE99</td>
                        <td height="30" width="95" bgcolor="#ffdd55"> #FFDD55</td>
                        <td height="30" width="95" bgcolor="#ffcc22"> #FFCC22</td>
                        <td height="30" width="95" bgcolor="#ffbb00"> #FFBB00&nbsp;</td>
                        <td height="30" width="95" bgcolor="#ddaa00"> #DDAA00&nbsp;</td>
                        <td height="30" width="95" bgcolor="#aa7700"> #AA7700&nbsp;</td>
                        <td height="30" width="95" bgcolor="#886600"> #886600&nbsp;</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#ffffbb"> #FFFFBB</td>
                        <td height="30" width="95" bgcolor="#ffff77"> #FFFF77</td>
                        <td height="30" width="95" bgcolor="#ffff33"> #FFFF33</td>
                        <td height="30" width="95" bgcolor="#ffff00"> #FFFF00</td>
                        <td height="30" width="95" bgcolor="#eeee00"> #EEEE00</td>
                        <td height="30" width="95" bgcolor="#bbbb00"> #BBBB00</td>
                        <td height="30" width="95" bgcolor="#888800"> #888800</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#eeffbb"> #EEFFBB</td>
                        <td height="30" width="95" bgcolor="#ddff77"> #DDFF77</td>
                        <td height="30" width="95" bgcolor="#ccff33"> #CCFF33</td>
                        <td height="30" width="95" bgcolor="#bbff00"> #BBFF00</td>
                        <td height="30" width="95" bgcolor="#99dd00"> #99DD00</td>
                        <td height="30" width="95" bgcolor="#88aa00"> #88AA00</td>
                        <td height="30" width="95" bgcolor="#668800"> #668800</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#ccff99"> #CCFF99</td>
                        <td height="30" width="95" bgcolor="#bbff66"> #BBFF66</td>
                        <td height="30" width="95" bgcolor="#99ff33"> #99FF33</td>
                        <td height="30" width="95" bgcolor="#77ff00"> #77FF00</td>
                        <td height="30" width="95" bgcolor="#66dd00"> #66DD00</td>
                        <td height="30" width="95" bgcolor="#55aa00"> #55AA00</td>
                        <td height="30" width="95" bgcolor="#227700"> #227700</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#99ff99"> #99FF99</td>
                        <td height="30" width="95" bgcolor="#66ff66"> #66FF66</td>
                        <td height="30" width="95" bgcolor="#33ff33"> #33FF33</td>
                        <td height="30" width="95" bgcolor="#00ff00"> #00FF00</td>
                        <td height="30" width="95" bgcolor="#00dd00"> #00DD00</td>
                        <td height="30" width="95" bgcolor="#00aa00"> #00AA00</td>
                        <td height="30" width="95" bgcolor="#008800"> #008800</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#bbffee"> #BBFFEE</td>
                        <td height="30" width="95" bgcolor="#77ffcc"> #77FFCC</td>
                        <td height="30" width="95" bgcolor="#33ffaa"> #33FFAA</td>
                        <td height="30" width="95" bgcolor="#00ff99"> #00FF99</td>
                        <td height="30" width="95" bgcolor="#00dd77"> #00DD77</td>
                        <td height="30" width="95" bgcolor="#00aa55"> #00AA55</td>
                        <td height="30" width="95" bgcolor="#008844"> #008844</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#aaffee"> #AAFFEE</td>
                        <td height="30" width="95" bgcolor="#77ffee"> #77FFEE</td>
                        <td height="30" width="95" bgcolor="#33ffdd"> #33FFDD</td>
                        <td height="30" width="95" bgcolor="#00ffcc"> #00FFCC</td>
                        <td height="30" width="95" bgcolor="#00ddaa"> #00DDAA</td>
                        <td height="30" width="95" bgcolor="#00aa88"> #00AA88</td>
                        <td height="30" width="95" bgcolor="#008866"> #008866</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#99ffff"> #99FFFF</td>
                        <td height="30" width="95" bgcolor="#66ffff"> #66FFFF</td>
                        <td height="30" width="95" bgcolor="#33ffff"> #33FFFF</td>
                        <td height="30" width="95" bgcolor="#00ffff"> #00FFFF</td>
                        <td height="30" width="95" bgcolor="#00dddd"> #00DDDD</td>
                        <td height="30" width="95" bgcolor="#00aaaa"> #00AAAA</td>
                        <td height="30" width="95" bgcolor="#008888"> #008888</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#cceeff"> #CCEEFF</td>
                        <td height="30" width="95" bgcolor="#77ddff"> #77DDFF</td>
                        <td height="30" width="95" bgcolor="#33ccff"> #33CCFF</td>
                        <td height="30" width="95" bgcolor="#00bbff"> #00BBFF</td>
                        <td height="30" width="95" bgcolor="#009fcc"> #009FCC</td>
                        <td height="30" width="95" bgcolor="#0088a8"> #0088A8</td>
                        <td height="30" width="95" bgcolor="#007799"> #007799</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#ccddff"> #CCDDFF</td>
                        <td height="30" width="95" bgcolor="#99bbff"> #99BBFF</td>
                        <td height="30" width="95" bgcolor="#5599ff"> #5599FF</td>
                        <td height="30" width="95" bgcolor="#0066ff"> #0066FF&nbsp;</td>
                        <td height="30" width="95" bgcolor="#0044bb"> #0044BB&nbsp;</td>
                        <td height="30" width="95" bgcolor="#003c9d"> #003C9D&nbsp;</td>
                        <td height="30" width="95" bgcolor="#003377"> #003377&nbsp;</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#ccccff"> #CCCCFF</td>
                        <td height="30" width="95" bgcolor="#9999ff"> #9999FF</td>
                        <td height="30" width="95" bgcolor="#5555ff"> #5555FF&nbsp;</td>
                        <td height="30" width="95" bgcolor="#0000ff"> #0000FF&nbsp;</td>
                        <td height="30" width="95" bgcolor="#0000cc"> #0000CC&nbsp;</td>
                        <td height="30" width="95" bgcolor="#0000aa"> #0000AA&nbsp;</td>
                        <td height="30" width="95" bgcolor="#000088"> #000088&nbsp;</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#ccbbff"> #CCBBFF</td>
                        <td height="30" width="95" bgcolor="#9f88ff"> #9F88FF</td>
                        <td height="30" width="95" bgcolor="#7744ff"> #7744FF&nbsp;</td>
                        <td height="30" width="95" bgcolor="#5500ff"> #5500FF&nbsp;</td>
                        <td height="30" width="95" bgcolor="#4400cc"> #4400CC&nbsp;</td>
                        <td height="30" width="95" bgcolor="#2200aa"> #2200AA&nbsp;</td>
                        <td height="30" width="95" bgcolor="#220088"> #220088&nbsp;</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#d1bbff"> #D1BBFF</td>
                        <td height="30" width="95" bgcolor="#b088ff"> #B088FF</td>
                        <td height="30" width="95" bgcolor="#9955ff"> #9955FF&nbsp;</td>
                        <td height="30" width="95" bgcolor="#7700ff"> #7700FF&nbsp;</td>
                        <td height="30" width="95" bgcolor="#5500dd"> #5500DD&nbsp;</td>
                        <td height="30" width="95" bgcolor="#4400b3"> #4400B3&nbsp;</td>
                        <td height="30" width="95" bgcolor="#3a0088"> #3A0088&nbsp;</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#e8ccff"> #E8CCFF</td>
                        <td height="30" width="95" bgcolor="#d28eff"> #D28EFF</td>
                        <td height="30" width="95" bgcolor="#b94fff"> #B94FFF&nbsp;</td>
                        <td height="30" width="95" bgcolor="#9900ff"> #9900FF&nbsp;</td>
                        <td height="30" width="95" bgcolor="#7700bb"> #7700BB&nbsp;</td>
                        <td height="30" width="95" bgcolor="#66009d"> #66009D&nbsp;</td>
                        <td height="30" width="95" bgcolor="#550088"> #550088&nbsp;</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#f0bbff"> #F0BBFF</td>
                        <td height="30" width="95" bgcolor="#e377ff"> #E38EFF</td>
                        <td height="30" width="95" bgcolor="#d93eff"> #E93EFF&nbsp;</td>
                        <td height="30" width="95" bgcolor="#cc00ff"> #CC00FF&nbsp;</td>
                        <td height="30" width="95" bgcolor="#a500cc"> #A500CC&nbsp;</td>
                        <td height="30" width="95" bgcolor="#7a0099"> #7A0099&nbsp;</td>
                        <td height="30" width="95" bgcolor="#660077"> #660077&nbsp;</td>
                    </tr>
                    <tr>
                        <td height="30" width="95" bgcolor="#ffb3ff"> #FFB3FF</td>
                        <td height="30" width="95" bgcolor="#ff77ff"> #FF77FF</td>
                        <td height="30" width="95" bgcolor="#ff3eff"> #FF3EFF&nbsp;</td>
                        <td height="30" width="95" bgcolor="#ff00ff"> #FF0&nbsp;0FF&nbsp;</td>
                        <td height="30" width="95" bgcolor="#cc00cc"> #CC00CC&nbsp;</td>
                        <td height="30" width="95" bgcolor="#990099"> #990099&nbsp;</td>
                        <td height="30" width="95" bgcolor="#770077"> #770077&nbsp;</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
const convert = require('color-convert');

export default {
    components: {},
    data() {
        return {
            HEXInput: '',
            RGBOut: '',
            HEXOut: '',
            RGBInput: '',

        }
    },
    methods: {
        is_valid_number(str) {
            if (/^\d+$/.test(str)) {  // 判断字符串是否只包含数字字符
                console.log(str)
                let number = parseInt(str);  // 将字符串转换为整数
                if (number >= 0 && number <= 255) {  // 判断整数是否在范围0-255之间
                    return true;
                }
            }
            return false;
        }
    },
    watch: {
        HEXInput: function (newValue, oldValue) {
            if (newValue.length === 4 || newValue.length === 7) {
                if (newValue[0] === '#') {
                    if (newValue.length === 4) {
                        this.RGBOut = convert.hex.rgb(newValue).toString()
                    } else if (newValue.length === 7) {
                        this.RGBOut = convert.hex.rgb(newValue).toString()
                    }
                }
            }
        },
        RGBInput: function (newValue, oldValue) {
            let list = newValue.split(',')
            if (list.length === 3) {
                let exeStart = true
                for (let i = 0; i < list.length; i++) {
                    if (!this.is_valid_number(list[i])){
                        exeStart = false;
                        break;
                    }
                }
                if (exeStart){
                    this.HEXOut = '#'+ convert.rgb.hex(list);
                }
            }
        },

    }
}
</script>
<style lang="less" scoped>
.box {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgb(245, 245, 245, 0);
    background: rgb(245, 245, 245);

    .tool-content {
        width: calc(100% - 25vw);
        max-width: 1600px;
        margin: 1vw auto 0;
    }

    .table-box {
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        //border: 1px solid red;

        table {
            margin: 0 auto;
            box-sizing: border-box;
            //border: 1px solid red;
            min-width: calc(7 * 90px);
        }
    }
    .count-input{
        width: 70%;
        margin: 0 auto;
    }
}
</style>
