<template>
    <div class="ai-box">
        <div class="explain">
            很抱歉因为资金原因,原来的服务器已经<span style="color: red">停机</span>!
        </div>
        <div class="ai-tip">
            ↓如下是<span style="color: #D83D6C">临时</span>公开的访问地址<span style="color: limegreen">(免费使用)</span>↓
        </div>
        <el-link type="success">
            <div class="ai-url" @click="goAi()">
                https://ai.zhoulujiang.cn/
            </div>
        </el-link>
        <div class="sponsor">
            <div>新的AI框架和服务器正在缓慢搭建中</div>
            <div>域名还是不会改变:http://zhoulujiang.com/</div>
            <div>↓有支持的赞助一下↓</div>
            <div class="sponsor-img">
                <div class="demo-image__preview">
                    <el-image
                        :src="'https://zhoululu.oss-cn-shenzhen.aliyuncs.com/web/2541f8ff-e05f-4daf-bd6f-a4cd330b812f.jpg'"
                        :preview-src-list="['https://zhoululu.oss-cn-shenzhen.aliyuncs.com/web/2541f8ff-e05f-4daf-bd6f-a4cd330b812f.jpg']">ek'
                    </el-image>
                </div>
            </div>
        </div>
        <div>
            帮助反馈联系Q/V:1822015445
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    methods: {
        goAi(){
            window.open('https://ai.zhoulujiang.cn/', '_blank')
        }
    }
}

</script>


<style scoped lang="less">
.ai-box{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    .ai-url{
        font-size: 2rem;
    }
    .sponsor{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }
    .sponsor-img{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .demo-image__preview{
        margin-top: 1rem;
        width: 11rem;
        height: 16rem;
    }

}
</style>