/**
 * 重新封装网络请求
 */
import axios from 'axios'
import Vue from "vue";
import store from '@/store'
import {ElMessage} from '@/utils/global'

let BASE_API = 'http://zhoulujiang.cn:8880/digitalize-teaching'
Vue.prototype.BASE_API = BASE_API

//基本参数设置
export const request = axios.create({
    /*配置请求的根路径*/
    baseURL: '/api',
    /*5000毫秒以上超时*/
    timeout: 5000,
})

//请求设置
request.interceptors.request.use(config => {
    if (store.state.token) {
        //config.headers['token'] = getToken()
    }
    return config;
}, error => {
    return Promise.reject(error)
})

//响应设置
request.interceptors.response.use(response => {
    // console.log(response)
    const res = response.data
    if(response.config.url.indexOf('/zfb/pay')!=-1){
        return  response
    }
    if (res.code !== 200) {
        ElMessage(res.message || 'Error', 'error')
        return Promise.reject(new Error(res.message || 'Error'))
    } else if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        ElMessage(res.message || 'Illegal token', 'error')
        return Promise.reject(new Error(res.message || 'Error'))
    } else {
        return res;
    }
}, error => {
    ElMessage(error.message, error)
    return Promise.reject(error)
})
export default request
