<template>
    <div class="box">

    </div>
</template>

<script>

export default {
    data() {
        return {

        };
    },
    mounted() {


    },
    methods:{

    }

};
</script>

<style lang="less" scoped>
.box{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
</style>
