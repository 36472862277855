import request from '@/utils/request'
//定义访问常量
const api_name = '/aly-file-authority'
export default {
    //获取权限
    authority() {
        return request({
            //接口路径
            url: `${api_name}/public`,
            //提交方式
            method: 'get',
        })
    },
}