import {Message} from 'element-ui'


const TokenKey = 'vue_admin_template_token'

export function ElMessage(message, type, duration = 5) {
    Message({
        message: message,
        center: true,
        type: type,
        duration: duration * 1000
    })
}




