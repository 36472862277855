<template>
    <div id="index">
        <div v-if="navigation" class="nav">
            <div class="log">
                <!--<img src="@/assets/images/log.png">-->
                <el-link :underline="false" class="title" @click="goto('/home','home')">默默的幻想的个人网站</el-link>
            </div>
            <div class="menu">
                <div class="content">
                    <div class="item">
                        <el-link :class="{'active':$route.name=='home'}" :underline="false" @click="goto('/home','home')">首页</el-link>
                    </div>
                    <!--<div class="item">
                        <el-link :class="{'active':$route.name=='means'}" :underline="false" @click="goto('/means','means')">个人简历</el-link>
                    </div>-->
                    <div class="item">
                        <el-link :class="{'active':$route.name=='skill'}" :underline="false" @click="goto('/skill','skill')">个人技能</el-link>
                    </div>
                    <div class="item">
                        <el-link :class="{'active':$route.name=='works'}" :underline="false" @click="goto('/works','works')">个人作品</el-link>
                    </div>
                    <div class="item">
                        <el-link :class="{'active':$route.name=='like'}" :underline="false" @click="goto('/like','like')">个人爱好</el-link>
                    </div>
                    <div class="item">
                        <el-link :class="{'active':$route.name=='tool'}" :underline="false" @click="goto('/tool','tool')">个人工具</el-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="router-view">
            <router-view/>
        </div>
    </div>
</template>

<script>
export default {
    name: "IndexView",
    data() {
        return {
            pageWidth: window.innerWidth,
            navigation: true,
        }
    },
    created() {
        if (this.$store.state.pageWidth <= 0) {
            this.navigation = false
        } else {
            this.navigation = true
        }
    },
    methods: {
        //路由页面跳转
        goto(path, name, val) {
            let pathNow = this.$route.path
            if (pathNow != path) {
                this.$router.push({
                    path: path,
                    name: name,
                    params: val
                });
            }
        },
    },
    computed: {
        listenWidth() {
            return this.$store.state.pageWidth;
        }
    },
    watch: {
        listenWidth: function (newVal, oldVal) {
            if (newVal <= 0) {
                this.navigation = false
            } else {
                this.navigation = true
            }
        }
    }
}
</script>

<style scoped>
#index {
    width: 100%;
    height: 100%;

}

.nav {
    width: 100%;
    //background: url("https://zhoululu.oss-cn-shenzhen.aliyuncs.com/web/58deeb8a-9118-404c-89b4-af2d7909e3cf.jpg");
    background: url("https://zhoululu.oss-cn-shenzhen.aliyuncs.com/web/21e25e4e-0e6f-4949-a479-d79c1c03b6c5.jpg");
    background-size: 100% auto;
    display: flex;
    justify-content: space-between;
    margin: auto;
    height: 60px;
    line-height: 55px;
    box-shadow: 0px 2px 2px 0px #ff5656;
    z-index: 999;
    -webkit-user-select: none;
}

.nav a {
    text-decoration: none;
    color: #000000;
    font-weight: bolder;
}

.title {
    font-size: 24px;
    letter-spacing: 4px;
    text-align: center;
    margin-left: 11vw;
    color: #000000;
    font-weight: bolder;
}

.title img {
    height: 50px;
}

.menu {
    margin-right: 11vw;
    text-align: center;
    height: 50px !important;
}

.item {
    display: inline-block;
    margin-left: 30px;
    font-weight: bolder;
    cursor: pointer;
    line-height: 60px;
    font-size: 20px !important;
}
.router-view{
    width: 100%;
    height: calc(100% - 60px);
}
.active {
    color: #0079ff !important;
}


</style>
