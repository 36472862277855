<template>
    <div class="box">

    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    created() {},
    mounted() {

    },
    methods: {

    },
};
</script>

<style lang="less" scoped>

</style>