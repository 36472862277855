<template>
    <div class="big-box">
        <h2>登录成功</h2>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    methods: {}
}

</script>


<style scoped lang="less">
.big-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>