<template>
    <div>
        <!--  100个盒子整齐排列 多出当前宽度换行 每个盒子 100px 高和宽   -->
        <div class="out-box">
            <div class="box" v-for="item in 100">{{ item }}</div>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    methods: {
        getLatestId(count = 1) {
            let submitParam = {
                pTableName: 'lt_style_fty_issue',
                pKeyFieId: 'sID',
                pQty: 2
            }
            this.pushCustomDataToCloud('get_id', submitParam, 'get_id查询', 1)
            return new Promise((resolve, reject) => {
                let setTime = 0;
                let res = {
                    code: -1,
                    data: []
                }
                let interval = setInterval(() => {
                    if (this.isIdValue) {
                        res.code = 0;
                        res.data = this.idValue;
                        this.isIdValue = false
                        this.idValue = []
                        resolve(res)
                        clearInterval(interval);
                    } else if (setTime > 30) {
                        resolve(res)
                        clearInterval(interval);
                    }
                }, 1000);
            })
        },
    }
}

</script>


<style scoped lang="less">
.out-box{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .box {
        width: 100px;
        height: 100px;
        border: 1px solid red;
    }
}


</style>