<template>
    <div class="box">
        <div class="tool-content">
            <o-s-s-upload-file ref="OSSUploadFileFef" @successResponded="successResponded" @delFile="delFile"/>
            <el-input v-model="path" :disabled="true">
                <template slot="prepend">文件地址</template>
            </el-input>
        </div>
    </div>
</template>

<script>
import OSSUploadFile from "@/components/OSSUploadFile.vue";
export default {
    components:{OSSUploadFile},
    data() {
        return {
            path: '',
        }
    },
    methods: {
        //上传完成返回url
        successResponded(url){
            this.path = url
        },
        //重新选择
        delFile(){
            this.path=''
        }
    }
}
</script>

<style scoped>
.box {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgb(245, 245, 245, 0);
    background: rgb(245, 245, 245);

    .tool-content {
        width: calc(100% - 25vw);
        max-width: 1600px;
        margin: 1vw auto 0;
    }
}
</style>
