<template>
    <div class="box">
        <div class="tool-content">
            <div class="item-list">
                <div class="item" v-for="(item,index) in toolList" :key="index" @click="goto(item.jumpUrl,item.jumpName,item.jumpType)">
                    <div class="content">
                        <div class="details">
                            <div class="picture">
                                <div class="mask"/>
                                <img :src="item.url" alt="图片">
                            </div>
                            <div class="title">
                                <div class="txt">
                                    {{ item.name }}
                                </div>
                                <div class="bottom-content">
                                    <div class="time">
                                        {{ item.time }}
                                    </div>
                                    <div class="tag">
                                        {{ item.jumpType === 1 ? '站内' : '站外'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="item" v-for="(item,index) in 3" :key="(index+100)" @click="goto(item.jumpUrl,item.jumpName,item.jumpType)">
                    <div class="content">
                        <div class="details">
                            <div class="picture">
                                <div class="mask"/>
                                <img src="https://zhoululu.oss-cn-shenzhen.aliyuncs.com/web/9bac41ff-867d-44da-8058-f19af80d07cd.jpg" alt="图片">
                            </div>
                            <div class="title">
                                <div class="txt">
                                    {{ '开发中' }}
                                </div>
                                <div class="bottom-content">
                                    <div class="time">
                                        2023年9月30日
                                    </div>
                                    <div class="tag">
                                        {{ '无效' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            toolList: [
                {
                    name: 'RustDesk远程桌面',
                    url: 'https://zhoulujiang.cn/company/RustDesk.assets/image-20240629090530946.png',
                    jumpType: 2,
                    jumpUrl: 'https://zhoulujiang.cn/company/RustDesk.html',
                    jumpName: '',
                    time: '2024年6月29日'
                },
                {
                    name: 'AI问答 与 AI文档',
                    url: 'https://zhoulujiang.cn/company/AiStudy.assets/%E8%BF%85%E6%8D%B7%E5%9B%BE%E7%89%87%E8%BD%AC%E6%8D%A2%E5%99%A8-202433-1709455196514.png',
                    jumpType: 2,
                    jumpUrl: 'https://zhoulujiang.cn/company/AiStudy.html',
                    jumpName: '',
                    time: '2024年5月3日'
                },
                {
                    name: 'AI工具',
                    url: 'https://zhoululu.oss-cn-shenzhen.aliyuncs.com/web/2657953b-32fa-4088-96fc-13db4d92fd28.png',
                    jumpType: 1,
                    jumpUrl: '/ai-doc',
                    jumpName: 'ai-doc',
                    time: '2024年5月3日'
                },
                {
                    name: '颜色在线转换 和 日常颜色大全',
                    url: 'https://zhoululu.oss-cn-shenzhen.aliyuncs.com/web/dd809e7a-a9d6-42e8-8bb7-91ff4584687e.png',
                    jumpType: 1,
                    jumpUrl: '/css-color',
                    jumpName: 'css-color',
                    time: '2023年9月30日'
                },
                {
                    name: '文件临时上传工具',
                    url: 'https://zhoululu.oss-cn-shenzhen.aliyuncs.com/web/d13f7d51-167b-4d1c-82ac-f8c9942e22b1.jpg',
                    jumpType: 1,
                    jumpUrl: '/file-test',
                    jumpName: 'file-test',
                    time: '2023年9月30日'
                },
                {
                    name: '内网穿透NPS工具',
                    url: 'https://zhoululu.oss-cn-shenzhen.aliyuncs.com/web/725a2617-2535-4bcf-97e2-e4c7aaaf8db5.jpg',
                    jumpType: 2,
                    jumpUrl: 'http://zhoulujiang.tpddns.cn:88',
                    jumpName: '',
                    time: '2023年9月30日'
                },
                {
                    name: '公网局域网ZeroTier工具',
                    url: 'https://zhoululu.oss-cn-shenzhen.aliyuncs.com/web/fd01fe1e-526e-42b7-af62-818831465832.png',
                    jumpType: 2,
                    jumpUrl: 'http://zhoulujiang.tpddns.cn:4000/login',
                    jumpName: '',
                    time: '2023年9月30日'
                },
                {
                    name: '私有云盘SeaFile工具',
                    url: 'https://zhoululu.oss-cn-shenzhen.aliyuncs.com/web/35e5ea62-0787-415e-bc61-07581568d891.jpg',
                    jumpType: 2,
                    jumpUrl: 'http://zhoulujiang.tpddns.cn:8283',
                    jumpName: '',
                    time: '2023年9月30日'
                }
            ]
        };
    },
    mounted() {


    },
    methods: {
        //路由页面跳转
        goto(path, name, type) {
            if (type === 1) {
                let pathNow = this.$route.path
                if (pathNow != path) {
                    this.$router.push({
                        path: path,
                        name: name,
                    });
                }
            } else if (type === 2) {
                window.open(path, '_blank')
            }
        }
    }

};
</script>

<style lang="less" scoped>
.box {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgb(245, 245, 245, 0);
    background: rgb(245, 245, 245);
    -webkit-user-select: none;

    .tool-content {
        width: calc(100% - 25vw);
        max-width: 1600px;
        margin: 1vw auto 0;
    }

    .item-list {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        box-sizing: border-box;
        //border: 1px solid red;

        .item {
            width: 25%;
            height: 15vw;
            max-height: 330px;
            box-sizing: border-box;
            //border: 1px solid red;

            .content {
                width: 100%;
                height: 100%;
                padding: 5%;
                box-sizing: border-box;
                //border: 1px solid red;

                .details {
                    border-radius: 5%;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    //border: 1px solid red;
                    cursor: pointer;
                    background: #FFFFFF;
                    overflow: hidden;

                    .picture {
                        position: relative;
                        width: 100%;
                        height: 70%;
                        box-sizing: border-box;
                        //border: 1px solid red;
                        opacity: 1;

                        img {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            box-sizing: border-box;
                            //border: 1px solid red;
                            z-index: 8;

                        }

                        .mask {
                            position: absolute;
                            z-index: 9;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: rgba(0, 0, 0, 1);
                            opacity: 0;
                            box-sizing: border-box;
                            //border: 1px solid red;
                        }
                    }

                    .title {
                        width: 100%;
                        height: 30%;
                        box-sizing: border-box;
                        //border: 1px solid red;
                        padding: 3%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        background: #FFFFFF;

                        .txt {
                            font-size: clamp(0.5rem, 0.5vw, 1rem);
                            font-weight: 600;
                            box-sizing: border-box;
                            //border: 1px solid red;
                        }

                        .bottom-content {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            font-size: clamp(0.5rem, 0.5vw, 1rem);
                            font-weight: 400;
                            box-sizing: border-box;
                            //border: 1px solid red;

                            .time {

                            }

                            .tag {
                                color: #409eff;
                                border: 1px solid #409eff;
                                text-align: center;
                                width: 20%;
                                border-radius: 5%;
                            }
                        }
                    }
                }

                .details:hover {
                    .picture {

                        .mask {
                            opacity: 0.1;
                        }
                    }

                    .title {
                        color: #8ecfe8;
                    }

                }
            }

        }
    }
}
</style>
