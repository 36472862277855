import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        pageWidth: window.innerWidth
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
})
