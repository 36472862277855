<template>
    <div>
        <h1>二维码</h1>
    </div>
</template>

<script>

export default {
    components: {},
    data() {
        return {}
    },
    methods: {}
}
</script>
<style lang="less" scoped>

</style>
