<template>
    <div class="big-box">
        <el-button type="success" @click="authClick()">点击授权</el-button>
    </div>
</template>
<script>
import axios from "axios";

export default {
    data() {
        return {
            authUrl: null,
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.getAuthUrl()
        })
    },
    methods: {
        authClick() {
            window.open(this.authUrl,'_self')
        },
        getAuthUrl() {
            console.log(this.$route.query);
            let vm = this
            axios.get('https://zhoulujiang.cn/ry/custom/auth-url')
                .then(function (response) {
                    vm.authUrl = response.data.data.wxUrl
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
}

</script>


<style scoped lang="less">
.big-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>