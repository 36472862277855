import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import IndexView from '../views/IndexView.vue'
import Like from '../views/like/Index.vue'
import Means from '../views/means/Index.vue'
import Works from '../views/works/Index.vue'
import Skill from '../views/skill/Index.vue'
import Tool from '../views/tool/Index.vue'
import QRCode from '../views/tool/qrCode/Index.vue'
import CssColor from '../views/tool/cssColor/Index.vue'
import FileTest from '../views/tool/fileTest/Index.vue'
import aiDoc from '../views/tool/aiDoc/Index.vue'
import WXSuccess from '@/views/wx/Success.vue'
import WXLogin from '@/views/wx/Login.vue'
import Test from "@/views/Test.vue";

Vue.use(VueRouter)

const routes = [
    {path: '*', redirect: '/home'},
    {path: '/', redirect: '/home'},
    {
        path: '/wx-success',
        name: 'wx-success',
        component: WXSuccess,
    },
    {
        path: '/test',
        name: 'test',
        component: Test,
    },
    {
        path: '/wx-login',
        name: 'wx-login',
        component: WXLogin,
    },
    {
        path: '/',
        name: 'IndexView',
        component: IndexView,
        redirect: '/home',
        children: [
            {
                path: 'home',
                name: 'home',
                component: HomeView
            },
            {
                path: 'like',
                name: 'like',
                component: Like
            },
            {
                path: 'means',
                name: 'means',
                component: Means
            },
            {
                path: 'works',
                name: 'works',
                component: Works
            },
            {
                path: 'skill',
                name: 'skill',
                component: Skill
            },
            {
                path: 'tool',
                name: 'tool',
                component: Tool,
            },
            {
                path: 'qr-code',
                name: 'qr-code',
                component: QRCode
            }, {
                path: 'css-color',
                name: 'css-color',
                component: CssColor
            }, {
                path: 'file-test',
                name: 'file-test',
                component: FileTest
            },
            {
                path: 'ai-doc',
                name: 'ai-doc',
                component: aiDoc
            },
        ]
    },
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
