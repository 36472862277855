<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.$store.state.pageWidth = window.innerWidth
    },
  }
}
</script>
<style>

</style>
