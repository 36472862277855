<template>
    <div id="home">
        <div :class="{'box-min':!navigation}" class="box">
            <div class="top">
                <img alt="" src="@/assets/images/header.png"/>
                <div class="userName">
                    周露江
                </div>
                <div class="explain">
                    生活不是一种宿命,而是一种选择!
                </div>
            </div>
            <div class="bottom">
                <div class="content">
                    <!--<div class="item" @click="goto('/means','means')">
                        <a>
                            <img alt="" src="@/assets/images/means.png">
                            <span>个人简历</span>
                        </a>
                    </div>-->
                    <div class="item" @click="goto('/skill','skill')">
                        <a>
                            <img alt="" src="@/assets/images/means.png">
                            <span>个人技能</span>
                        </a>
                    </div>
                    <div class="item" @click="goto('/works','works')">
                        <a>
                            <img alt="" src="@/assets/images/works.png">
                            <span>个人作品</span>
                        </a>
                    </div>
                    <div class="item" @click="goto('/like','like')">
                        <a>
                            <img alt="" src="@/assets/images/like.png">
                            <span>个人爱好</span>
                        </a>
                    </div>
                    <div class="item" @click="goto('/tool','tool')">
                        <a>
                            <img alt="" src="@/assets/images/skill.png">
                            <span>个人工具</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="icp">
            <div  @click="jumpICP()" class="icp-txt">互联网ICP备案: 湘ICP备2021016827号-1</div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'HomeView',
    data() {
        return {
            navigation: false
        }
    },
    created() {
        if (this.$store.state.pageWidth <= 750) {
            this.navigation = false
        } else {
            this.navigation = true
        }
    },
    methods: {
        jumpICP() {
            window.open('https://beian.miit.gov.cn/')
        },
        //路由页面跳转
        goto(path, name, val) {
            let pathNow = this.$route.path
            if (pathNow != path) {
                this.$router.push({
                    path: path,
                    name: name,
                    params: val
                });
            }
        }
    },
    computed: {
        listenWidth() {
            return this.$store.state.pageWidth;
        }
    },
    watch: {
        listenWidth: function (newVal, oldVal) {
            if (newVal <= 750) {
                this.navigation = false
            } else {
                this.navigation = true
            }
        }
    }
}
</script>
<style scoped>
.box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    width: 100%;
    height: calc(100vh - 60px);
    //background: url("https://zhoululu.oss-cn-shenzhen.aliyuncs.com/web/21e25e4e-0e6f-4949-a479-d79c1c03b6c5.jpg");
    background: #77ddff;
    background-size: 100% auto;
    z-index: 0;
    -webkit-user-select: none;
}

.box-min {
    height: 100vh;
}

.top {
    margin-top: -5%;
    width: 100%;
    text-align: center;
}

.top img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

.userName {
    color: #000000;
    font-weight: bolder;
    font-size: 30px;
    margin: 20px 0;
    letter-spacing: 5px;
}

.explain {
    color: #000000;
    font-size: 19px;
    letter-spacing: 5px;
}

.bottom {
    width: 100%;
    margin-top: 40px;
}

.bottom img {
    width: 100px;
    height: 100px;
}

.content {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
}

.item {
    list-style: none;
    width: 20%;
    border-radius: 15px;
    cursor: pointer;
    border: 2px solid;
    border-image: linear-gradient(270deg, #2569FF, #D83D6C) 1 1;
    clip-path: inset(0 round 2px);
    background-clip: padding-box;
    box-sizing: border-box;
    display: flex;
    justify-self: flex-start;
    align-items: center;
    opacity: inherit;
    background-color: rgba(171, 191, 229, 0.1);
}
.item:hover{

}
.item a {
    margin: 20px 0;
    display: flex;
    width: 100%;
    height: 130px;
    align-content: center;
    flex-wrap: wrap;
    text-decoration: none;
    color: #ff8f02;
    justify-content: center;
}

.item span {
    display: block;
    width: 100%;
    text-align: center;
}

.icp {
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 20px;
    width: 100%;
}

.icp-txt{
    text-align: center;
    color: #000000;
    letter-spacing: 3px;
    font-size: 16px;
    cursor: pointer;
}
.icp-txt:hover{
    color: #3329ff;
}

@media (max-width: 600px) {
    .top img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }

    .bottom img {
        width: 50px;
        height: 50px;
    }

    .content {
        width: 95%;
    }

    .item {

        width: 80px;
    }

    .item a {
        height: 80px;
    }

    .explain {
        letter-spacing: 4px;
    }
}
</style>
